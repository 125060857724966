import PropTypes from 'prop-types';

import { ORDER_TYPES } from '../../../constants';

export const consultationTreatment = PropTypes.shape({
    reference: PropTypes.number.isRequired,
    treatment: PropTypes.string.isRequired,
    gender: PropTypes.oneOf(['n/a', 'male', 'female']),
    type: PropTypes.oneOf(Object.keys(ORDER_TYPES).map((type) => ORDER_TYPES[type])).isRequired,
    label: PropTypes.string,
    quantity: PropTypes.number,
    total: PropTypes.number,
});
