'use client';

import React from 'react';

import { useAssessmentContext } from '@/data/context/assessmentContext/assessmentContext';

import Assessment from './Assessment';
import Modal from '../_ui/Modal/Modal';

/**
 * Component for hosting the assessment files. Only should lazy load in the assessment files in
 * once an assessment has been clicked.
 */
const AssessmentWrapper = () => {
    const { treatmentName, closeAssessment } = useAssessmentContext();

    return (
        <Modal show={!!treatmentName} onClose={closeAssessment} className="container-large w-full h-full" color="canvas">
            <Assessment />
        </Modal>
    );
};

export default AssessmentWrapper;
