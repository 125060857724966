import React from 'react';
import trimStart from 'lodash.trimstart';

import Textarea from '../../../../_ui/_blocks/Form/Inputs/Textarea/Textarea';

type TextareaProps = React.ComponentProps<typeof Textarea>;

interface Props extends Omit<TextareaProps, 'onChange'> {
    onChange: (val: string) => void;
}

const MoreDetail = ({ name, onChange, isDirty, isValid, value = '' }: Props) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = trimStart(e.target.value);
        onChange(val);
    };

    return (
        <Textarea
            id={`more-detail-${name}`}
            className="w-full mt-100"
            rows={5}
            name={name}
            onChange={handleOnChange}
            placeholder="Please provide details"
            value={value}
            isDirty={isDirty}
            isValid={isValid}
        />
    );
};

export default MoreDetail;
