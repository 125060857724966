/**
 * Grab an answer object. if its yes or no then say yes or no then add the detail after in a string.
 * @param {*} answer
 */
const getAnswerString = (answer) => {
    if (!answer) return null;

    if (answer.type === 'yes' || answer.type === 'no' || answer.type === 'yes-no') {
        return `${answer.answer ? 'Yes' : 'No'}${answer.detail ? ` - ${answer.detail}` : ''}`;
    }

    return `${answer.answer}${answer.detail ? ` - ${answer.detail}` : ''}`;
};

/**
 * Format the assessment data for the API.
 * @param {object} formData - Answers, treatment name and contact details from the assessment.
 */
export const formatAssessment = (data) => {
    const { contactDetails, answers, treatmentName } = data;

    /**
     * desconstructed contact details.
     */
    const { assessmentfullname, fullName, age, email, tel } = contactDetails;

    /**
     * Formatted answers for the assessment, see getAnswerString.
     */
    const formattedAnswers = {};
    Object.keys(answers).forEach((key) => {
        formattedAnswers[key] = getAnswerString(answers[key]);
    });

    return {
        for: treatmentName,
        assessmentfullname: assessmentfullname || null,
        name: fullName,
        age,
        email,
        telephone: tel,
        answers: formattedAnswers,
    };
};
