import React from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';

import { useAssessmentContext } from '../../../../data/context/assessmentContext/assessmentContext';

import TextInput from '../../../_ui/_blocks/Form/Inputs/TextInput/TextInput';
import PanelWrapper from '../../../_ui/Panels/PanelWrapper';
import { JoiForm } from '../../../_ui/_blocks/Form/JoiForm/JoiForm';
import JoiFormInputWrap from '../../../_ui/_blocks/Form/JoiForm/JoiFormInputWrap/JoiFormInputWrap';
import FieldWrapper from '../../../_ui/_blocks/Form/FieldWrapper/FieldWrapper';
import AssessmentSkeleton from '../../AssessmentSkeleton/AssessmentSkeleton';
import NumberInput from '../../../_ui/_blocks/Form/Inputs/NumberInput/NumberInput';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Card from '../../../_ui/_blocks/Card/Card';

interface Props {
    nextPanel: () => void;
}

const AssessmentContactDetails = ({ nextPanel }: Props) => {
    const { contactDetails, setContactDetails, closeAssessment } = useAssessmentContext();

    const updateFormState = (key: string | number, value: string) => setContactDetails((state: Record<string, string>) => ({ ...state, [key]: value }));

    const handleNext = () => nextPanel();

    return (
        <PanelWrapper
            title="Your details"
            description="Provide us with some details so our team can get back to you with the best advice possible."
            // TODO: fix this
            // @ts-ignore
            closePanel={closeAssessment}
        >
            {contactDetails ? (
                <JoiForm
                    schema={{
                        fullName: Joi.string().required().label('Name'),
                        age: Joi.number().required().label('Age'),
                        email: Joi.string()
                            .email({ tlds: { allow: false } })
                            .required()
                            .label('Contact Email'),
                        tel: Joi.string()
                            .regex(/^[0-9-+\s()]*$/)
                            .required()
                            .messages({ 'string.pattern.base': 'Please enter a valid contact number' })
                            .label('Contact Number'),
                    }}
                    formState={contactDetails}
                    onSubmit={handleNext}
                    options={{ allowUnknown: true }}
                >
                    <fieldset className="container-xsmall container-gutters-medium mx-auto">
                        <Card variant="bordered" className="space-y-100">
                            <div className="hidden !m-0">
                                {/* Honey Pot - assessmentfullname */}
                                <input
                                    id="form-field--assessment-full-name"
                                    name="assessmentfullname"
                                    type="text"
                                    aria-hidden="true"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFormState(e.target.name, e.target.value)}
                                    tabIndex={-1}
                                    autoComplete="off"
                                />
                            </div>
                            <JoiFormInputWrap validateOnChange name="fullName" className="!m-0">
                                <FieldWrapper id="form-field--full-name" label="Your name">
                                    <TextInput
                                        id="form-field--full-name"
                                        className="w-full"
                                        name="fullName"
                                        value={contactDetails.fullName || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            updateFormState(e.target.name, e.target.value)
                                        }
                                        autoComplete="name"
                                    />
                                </FieldWrapper>
                            </JoiFormInputWrap>
                            <JoiFormInputWrap validateOnChange name="age">
                                <FieldWrapper id="form-field--age" label="Your age">
                                    <NumberInput
                                        id="form-field--age"
                                        className="w-full"
                                        name="age"
                                        value={contactDetails.age || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            updateFormState(e.target.name, e.target.value)
                                        }
                                        type="number"
                                        min="0"
                                        max="130"
                                    />
                                </FieldWrapper>
                            </JoiFormInputWrap>
                            <JoiFormInputWrap validateOnChange name="email">
                                <FieldWrapper id="form-field--email" label="Contact email">
                                    <TextInput
                                        id="form-field--email"
                                        className="w-full"
                                        type="email"
                                        name="email"
                                        value={contactDetails.email || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            updateFormState(e.target.name, e.target.value)
                                        }
                                        autoComplete="email"
                                    />
                                </FieldWrapper>
                            </JoiFormInputWrap>
                            <JoiFormInputWrap validateOnChange name="tel">
                                <FieldWrapper id="form-field--contact-number" label="Contact Number">
                                    <TextInput
                                        id="form-field--contact-number"
                                        className="w-full"
                                        name="tel"
                                        value={contactDetails.tel || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            updateFormState(e.target.name, e.target.value)
                                        }
                                        type="tel"
                                        autoComplete="tel"
                                    />
                                </FieldWrapper>
                            </JoiFormInputWrap>
                        </Card>
                    </fieldset>

                    <div className="container-xsmall container-gutters-medium mx-auto flex justify-end gap-100 py-100">
                        <Button type="button" variant="secondary" onClick={closeAssessment} title="Cancel your assessment">
                            Cancel
                        </Button>
                        <Button type="submit" title="Continue and describe your symptoms">
                            Describe your symptoms
                        </Button>
                    </div>
                </JoiForm>
            ) : (
                <AssessmentSkeleton />
            )}
        </PanelWrapper>
    );
};

AssessmentContactDetails.propTypes = {
    nextPanel: PropTypes.func.isRequired,
};

export default AssessmentContactDetails;
