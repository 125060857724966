import axios from '@/lib/axios/axios';

export default {
    /**
     * The assessment resource allows a verified system to submit a free assessment request.
     * @param {object} data - The assessment data to post
     * @returns {object} - {status, message}
     */
    postAssessment: async (data) => axios.post('/assessments', data),
};
