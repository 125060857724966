import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '@/components/_ui/_blocks/Buttons/Button/Button';

import { useAssessmentContext } from '../../../../data/context/assessmentContext/assessmentContext';
import useScrollToFormErrors from '../../../_ui/_blocks/Form/hooks/useScrollToFormErrors';
import { filterConditionalQuestions } from '../../../Questions/helpers/filterConditional/filterConditional';

import PanelWrapper from '../../../_ui/Panels/PanelWrapper';
import Question from '../../../Questions/Question';

const questions = [
    {
        question_id: 'symptoms',
        question_type: 'free-text',
        question_text: 'What symptoms are you currently experiencing?',
        question_description: '',
        error_message: 'Please fill in this question',
        required: 1,
    },
    {
        question_id: 'symptoms_length',
        question_type: 'free-text',
        question_text: 'How long have you had these symptoms?',
        question_description: '',
        error_message: 'Please fill in this question',
        required: 1,
    },
    {
        question_id: 'other_conditions',
        question_type: 'yes-no',
        question_text: 'Have you ever been diagnosed with any other medical conditions?',
        question_description: '',
        error_message: 'Please fill in this question',
        more_detail_trigger: 1,
        required: 1,
    },
];

const AssessmentSymptoms = ({ nextPanel, previousPanel }) => {
    const { symptomAnswers, updateSymptomAnswers, checkSymptomAnswersAreValidAndFilterAnswers, closeAssessment } = useAssessmentContext();

    const filteredQuestions = filterConditionalQuestions(questions, symptomAnswers);

    const scrollToErrors = useCallback(useScrollToFormErrors('.question-card--has-error'), []);

    const handleSubmit = () => {
        if (!checkSymptomAnswersAreValidAndFilterAnswers(filteredQuestions.map((q) => q))) {
            scrollToErrors();
            return;
        }

        nextPanel();
    };

    return (
        <PanelWrapper
            title="Your symptoms"
            description="Tell us about your symptoms and any other conditions you currently suffer from"
            closePanel={closeAssessment}
            back={previousPanel}
        >
            <div className="space-y-100">
                {symptomAnswers
                    ? filteredQuestions.map((q) => (
                        <Question
                            {...q}
                            key={q.question_id}
                            id={q.question_id}
                            type={q.question_type}
                            text={q.question_text}
                            description={q.question_description}
                            errorMessage={q.error_message}
                            helper={q.helper_text}
                            required={q.required}
                            moreDetail={q.more_detail_trigger}
                            options={q.options}
                            conditional={q.conditional}
                            storedAnswer={symptomAnswers[q.question_id]}
                            updateAnswer={updateSymptomAnswers}
                        />
                    ))
                    : null}
                <div className="flex justify-end gap-100 py-100">
                    <Button
                        variant="secondary"
                        type="button"
                        className="assessment-actions__button assessment-actions__button--cancel"
                        onClick={closeAssessment}
                        title="Cancel your assessment"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" onClick={handleSubmit} title="Continue and inform us of your current medications">
                        Continue
                    </Button>
                </div>
            </div>
        </PanelWrapper>
    );
};

AssessmentSymptoms.defaultProps = {
    previousPanel: null,
};

AssessmentSymptoms.propTypes = {
    nextPanel: PropTypes.func.isRequired,
    previousPanel: PropTypes.func,
};

export default AssessmentSymptoms;
