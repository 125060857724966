'use client';

import React, { useMemo } from 'react';
import get from 'lodash.get';
import Image from 'next/image';

import { useCookieWatcher } from '../../../helpers/cookie';
import { getAffiliateJwtDecoded } from '../helpers/getAffiliateJwt';

import InPartnershipWithPatientLogo from './InPartnershipWithPatientLogo.svg';
import InPartnershipWithYoxlyLogo from './InPartnershipWithYoxlyLogo.svg';
import InPartnershipWithPillTimeLogo from './InPartnershipWithPillTimeLogo.svg';

const AffiliateBanner = () => {
    const [affiliateJwt] = useCookieWatcher('affiliate'); // we use this just to watch and perform rerenders.
    const decodedJwt = useMemo(() => getAffiliateJwtDecoded({}), [affiliateJwt]);
    const affiliateName = get(decodedJwt, 'affiliate_name', null);

    let affiliateImage: React.ReactNode | null = null;

    switch (affiliateName!) {
        case 'Patient Access':
            affiliateImage = (
                <Image
                    alt="Patient Access Logo"
                    src={InPartnershipWithPatientLogo}
                    width={266}
                    height={27}
                />
            );
            break;
        case 'Yoxly':
            affiliateImage = (
                <Image
                    alt="Yoxly Logo"
                    src={InPartnershipWithYoxlyLogo}
                    width={200}
                    height={21}
                />
            );
            break;
        case 'PillTime':
            affiliateImage = (
                <Image
                    alt="PillTime Logo"
                    src={InPartnershipWithPillTimeLogo}
                    width={247}
                    height={32}
                />
            );
            break;
        default:
            return null;
    }

    return (
        <div className="px-100 py-050">
            <div className="mx-auto container-large">
                {affiliateImage}
            </div>
        </div>
    );
};

export default AffiliateBanner;
