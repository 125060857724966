/**
 * Check if more detail is required to be shown.
 * @param {*} answer - answer value
 * @param {*} moreDetailTriggers - more detail value
 * @returns {boolean}
 */
const shouldShowMoreDetail = (answer, moreDetailTriggers) => {
    // Can't do !moreDetailTriggers as trigger could be a "0" or "false".
    if (typeof moreDetailTriggers === 'undefined' || moreDetailTriggers === null || moreDetailTriggers === '') {
        return false;
    }

    const triggers = Array.isArray(moreDetailTriggers) ? moreDetailTriggers : [moreDetailTriggers];
    const givenAnswer = Array.isArray(answer) ? answer : [answer];

    return triggers.findIndex((trigger) => givenAnswer.includes(trigger)) > -1;
};

export default shouldShowMoreDetail;
