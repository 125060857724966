/* eslint-disable camelcase */
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from '@/lib/dayjs/dayjs';

import { consultationTreatment } from '../Consultation/propTypes/consultationTreatment';
import getQuestionComponent from './QuestionTypes/helpers/getQuestionComponent';
import getErrorMessage from './QuestionTypes/helpers/getErrorMessage';
import shouldShowMoreDetail from './QuestionTypes/helpers/shouldShowMoreDetail';
import calculateQuestionValid from './QuestionTypes/helpers/calculateQuestionValid';
import isAnswerEmpty from './helpers/isAnswerEmpty';

import QuestionCard from '../_ui/Card/QuestionCard';
import PrefilledAnswer from './QuestionTypes/common/PrefilledAnswer/PrefilledAnswer';
import MoreDetail from './QuestionTypes/common/MoreDetail/MoreDetail';
import createAnswerEntity from './createAnswerEntity';
import usePrefillAnswer from './hooks/usePrefillAnswer';

const Question = ({
    id,
    type,
    text,
    description,
    errorMessage,
    helper,
    moreDetail: moreDetailTriggers,
    options,
    conditional,
    storedAnswer,
    updateAnswer,
    required,
    consultationMeta,
    ...question
}) => {
    // If the question is a conditional, it is always required. Otherwise, use the questions required flag.
    const isRequired = useMemo(() => (conditional && conditional.id ? 1 : required), [conditional, required]);
    const isMoreDetailDisplayed = shouldShowMoreDetail(storedAnswer.answer, moreDetailTriggers);

    // Handles the prefill state of the question.
    const updateAnswerOnPrefill = (prefilledAnswer) => updateAnswer(id, prefilledAnswer);
    const { isPrefilled, setIsPrefilled } = usePrefillAnswer(type, id, storedAnswer.answer, updateAnswerOnPrefill, question);

    const showError = useMemo(() => {
        /**
         * If more detail is shown base the error, shown on the more detail we dont want the more
         * detail, error arriving before someone interacts with something.
         */
        if (shouldShowMoreDetail(storedAnswer.answer, moreDetailTriggers)) {
            return !storedAnswer.questionValid;
        }

        /**
         * If the answer is dirty (via user interaction) then grab if the answer is valid or not to
         * decide whether to show.
         */
        if (storedAnswer.isDirty) return !storedAnswer.answerValid;

        return false;
    }, [
        shouldShowMoreDetail,
        storedAnswer.answer,
        storedAnswer.answerValid,
        storedAnswer.isDirty,
        storedAnswer.moreDetailDirty,
        storedAnswer.questionValid,
    ]);

    const errMsg = getErrorMessage(type, storedAnswer, moreDetailTriggers, isRequired, errorMessage);

    /**
     * Check the question type provided exists, if not return error message.
     */
    const QuestionTypeToRender = getQuestionComponent(type);
    if (!QuestionTypeToRender) return <p>Question type '{type}' not found.</p>;

    /**
     * Updates the answer to the question in local state.
     * @param {any} answer - The answer to the question.
     * @param {boolean} valid - If the answer is valid or not.
     * @param {boolean} isDirty - if the user has touched the question
     * @param {any} internalQuestionError - If the question has an error displayed internally. We use this to hide required / default messages on the view
     */
    // eslint-disable-next-line default-param-last
    const answerQuestion = (answer, answerValid, isDirty = true, internalQuestionError) => {
        // TODO: Fix this lint error when refactoring consultations.
        // eslint-disable-line default-param-last
        // Disabled the above eslint error as this would involve a lot of consultation testing.
        const hasAnswer = !isAnswerEmpty(answer);

        let isValid = hasAnswer && answerValid;
        if (!isRequired) isValid = hasAnswer ? answerValid : true;

        updateAnswer(
            id,
            createAnswerEntity(
                {
                    ...storedAnswer,
                    answerValid: isValid,
                    questionValid: calculateQuestionValid(answer, isValid, moreDetailTriggers, storedAnswer.detail),
                    answer,
                    isDirty,
                    internalQuestionError,
                    answered_on: dayjs.utc().valueOf(),
                },
                { type, isRequired }
            )
        );
    };

    /**
     * Updates the answer to the more detail in local state.
     * @param {string} detail - String containing more detail.
     */
    const answerMoreDetail = (detail, moreDetailDirty = true) => {
        updateAnswer(
            id,
            createAnswerEntity(
                {
                    ...storedAnswer,
                    questionValid: calculateQuestionValid(storedAnswer.answer, storedAnswer.answerValid, moreDetailTriggers, detail),
                    detail,
                    moreDetailDirty,
                    type,
                    answered_on: dayjs.utc().valueOf(),
                },
                { type, isRequired }
            )
        );
    };

    // TODO: in all of the question types, rename isValid to showError
    return (
        <QuestionCard
            id={id}
            question={text}
            description={description}
            answeredOn={isPrefilled ? storedAnswer.answered_on : 0}
            errorMessage={errMsg}
            helper={helper}
            required={isRequired}
            showError={showError}
            isDirty={storedAnswer.isDirty}
            type={type}
        >
            {isPrefilled ? (
                <PrefilledAnswer
                    questionType={type}
                    options={options}
                    answer={storedAnswer.answer}
                    moreDetail={isMoreDetailDisplayed ? storedAnswer.detail : undefined}
                    changeAnswer={() => setIsPrefilled(false)}
                />
            ) : (
                <Fragment>
                    <QuestionTypeToRender
                        {...question}
                        question_id={id}
                        name={`question_${id}`}
                        options={options}
                        onChange={answerQuestion}
                        value={storedAnswer.answer}
                        required={isRequired}
                        isValid={isMoreDetailDisplayed || !showError}
                        isDirty={storedAnswer.isDirty}
                        consultationMeta={consultationMeta}
                        setIsPrefilled={setIsPrefilled}
                    />
                    {isMoreDetailDisplayed ? (
                        <MoreDetail
                            name={`question_${id}_more_detail`}
                            value={storedAnswer.detail}
                            onChange={answerMoreDetail}
                            isDirty={storedAnswer.isDirty}
                            isValid={!showError}
                        />
                    ) : null}
                </Fragment>
            )}
        </QuestionCard>
    );
};

Question.defaultProps = {
    text: undefined,
    description: undefined,
    helper: undefined,
    options: [],
    conditional: null,
    moreDetail: undefined,
    errorMessage: undefined,
    storedAnswer: {
        answer: undefined,
    },
    consultationMeta: null,
};

Question.propTypes = {
    /** Question identifier */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Question types that we have coming through. */
    type: PropTypes.oneOf([
        'checkbox',
        'date',
        'free-text',
        'gender',
        'image',
        'multiple-selection',
        'no',
        'number',
        'radio',
        's-gp',
        's-bmi',
        'select',
        'yes',
        'yes-no',
        'undefined-type-for-testing',
    ]).isRequired,
    /** Question Title */
    text: PropTypes.string,
    /** Question Description */
    description: PropTypes.string,
    /** Enables a custom error message */
    errorMessage: PropTypes.string,
    /** Helper text incase the question is not clear to the user */
    helper: PropTypes.string,
    /** If this is set, we will ask for more details. */
    moreDetail: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.array]),
    /** Options to use in the question if its a multi select etc. */
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string])),
    conditional: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            answer: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number, PropTypes.bool]),
        }),
    ]),
    storedAnswer: PropTypes.object,
    updateAnswer: PropTypes.func.isRequired,
    required: PropTypes.number.isRequired,
    consultationMeta: consultationTreatment,
};

export default Question;
