import React from 'react';

import { useAssessmentContext } from '../../../../data/context/assessmentContext/assessmentContext';

import Attention from '../../../_ui/_blocks/Attention/Attention';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import InfoCard from '../../../_ui/Card/InfoCard';

const AssessmentCompleted = () => {
    const { closeAssessment } = useAssessmentContext();
    const handleClose = () => closeAssessment();

    return (
        <InfoCard
            title="Assessment complete"
            description="We'll be in touch as soon as we can; We strive to review assessments within one working day."
            animate
            flow="space-y-100"
            image={
                <img
                    src="/images/illustrations/consultation-start.svg"
                    alt="A doctor with a clipboard discusses a consultation with a patient"
                />
            }
        >
            <Attention heading="Is this a medical emergency?" type="warning">
                <p className="text-left">If this is a medical emergency, please call 999 or go to your nearest A&E department.</p>
            </Attention>
            <Button type="button" onClick={handleClose} className="w-full" title="End the assessment">
                End assessment
            </Button>
        </InfoCard>
    );
};

export default AssessmentCompleted;
