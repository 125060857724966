import React from 'react';

import Typography from '../_blocks/Typography/Typography';
import Card from '../_blocks/Card/Card';
import Icon from '../_blocks/Icon/Icon';

export interface Props {
    closeInfoCard?: undefined | (() => void);
    showHeading?: boolean;
    title?: string | undefined;
    description?: string | undefined;
    children?: React.ReactNode;
}

const InfoCard = ({
    children,
    closeInfoCard,
    showHeading = true,
    title,
    description,
}: Props) => (
    <div className="max-w-[24rem] mx-auto self-center p-100 md:p-150">
        <Card className="text-center">
            {closeInfoCard ? (
                <div className="pb-150 relative flex justify-between items-center w-full">
                    {closeInfoCard ? (
                        <button
                            className="flex p-0.75 border-none bg-transparent"
                            type="button"
                            onClick={closeInfoCard}
                            title="Close consultation?"
                        >
                            <Icon size="small" icon="cross-light" alt="cross Icon" />
                        </button>
                    ) : null}
                </div>
            ) : null}
            <div className="space-y-150">
                {showHeading ? (
                    <header className="space-y-0125">
                        {title ? <Typography as="h2" typeset="title" color="accent">{title}</Typography> : null}
                        {description ? (
                            <Typography
                                as="div"
                                typeset="paragraph"
                                color="quiet"
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        ) : null}
                    </header>
                ) : null}
                {children}
            </div>
        </Card>
    </div>
);

export default InfoCard;
