import React from 'react';
import PropTypes from 'prop-types';
// import { CSSTransition, SwitchTransition } from 'react-transition-group';

/**
 * This component is specifically for delaying some of the data being set in the css transitions
 * The should animate property will force the timeout to be 0 so that if we sholdnt animate it wont run.
 * This is fine although the data changes before the animations are finished.
 * So we re-set the 'should animate' locally after the entered animation has finished.
 * This ensures a full animation when we want it.
 */

// const [animate, setAnimate] = useState(shouldAnimate);
// ('panel to panel animation', keyName, shouldAnimate);
const PanelToPanelAnimation = ({
    children,
    // keyName,
    //  shouldAnimate
}) => (
    // <SwitchTransition mode="out-in">
    //     <CSSTransition
    //         key={keyName}
    //         onEntered={() => setAnimate(shouldAnimate)}
    //         timeout={animate ? 700 : 0}
    //         classNames="panel-animation"
    //     >
    <div className="h-full w-full flex items-center justify-center">{children}</div>
    //     </CSSTransition>
    // </SwitchTransition>
);
PanelToPanelAnimation.defaultProps = {
    // keyName: null
};

PanelToPanelAnimation.propTypes = {
    children: PropTypes.node.isRequired,
    // keyName: PropTypes.string,
    // shouldAnimate: PropTypes.bool.isRequired
};

export default PanelToPanelAnimation;
