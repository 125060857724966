'use client';

import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from '../data/context/authContext';
import { ConfirmationProvider } from '../data/context/confirmationContext';
import { AssessmentProvider } from '../data/context/assessmentContext/assessmentContext';
import SessionActivity from '../components/Authentication/SessionActivity/SessionActivity';
import { SearchProvider } from '../data/context/searchContext';
import { BasketProvider } from '../data/context/basketContext/basketContext';
import { SidebarNavigationProvider } from '../data/context/sidebarNavigationContext';
import { AccountNavigationProvider } from '../data/context/accountContext/accountNavigationContext';
import { ConsultationReorderProvider } from '../data/context/consultationContext/consultationReorderContext';

const queryClient = new QueryClient();

const Providers = ({ children }: { children: React.ReactNode }) => (
    <AuthProvider>
        <SearchProvider>
            <QueryClientProvider client={queryClient}>
                <ConfirmationProvider>
                    <AssessmentProvider>
                        <SidebarNavigationProvider>
                            <AccountNavigationProvider>
                                <BasketProvider>
                                    <ConsultationReorderProvider>{children}</ConsultationReorderProvider>
                                </BasketProvider>
                            </AccountNavigationProvider>
                        </SidebarNavigationProvider>
                    </AssessmentProvider>
                    <SessionActivity />
                </ConfirmationProvider>
            </QueryClientProvider>
        </SearchProvider>
    </AuthProvider>
);

export default Providers;
