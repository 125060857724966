import get from 'lodash.get';

import sendSentryMessage from '@/lib/sentry/helpers/sendSentryMessage';

const shouldThisShow = (currentQuestion, consultationAnswers) => {
    const conditionalId = get(currentQuestion, 'conditional.id', '');

    // Below is testing for an illusive conditional bug where the result of "consultationAnswers[currentQuestion.conditional.id]" is undefined fro ".answer". (Added 23/02/22))
    try {
        const test = consultationAnswers[currentQuestion.conditional.id].answer; // eslint-disable-line
    } catch (error) {
        const questionId = get(currentQuestion, 'question_id');
        sendSentryMessage('Conditional missing answer', (scope) => {
            scope.setExtra('Error message', error.message);
            scope.setExtra('Question ID', questionId);
            scope.setExtra('Question type', get(currentQuestion, 'question_type'));
            scope.setExtra('Question answer', get(consultationAnswers, `[${questionId}]`));
            scope.setExtra('Conditional ID', conditionalId);
        });
    }

    // The answer of the question specified by the conditional id provided.
    let parentAnswer = get(consultationAnswers, `[${conditionalId}].answer`);
    if (!Array.isArray(parentAnswer)) parentAnswer = [parentAnswer];

    // The values required to show this conditional question.
    const conditionalValue = Array.isArray(currentQuestion.conditional.value)
        ? currentQuestion.conditional.value
        : [currentQuestion.conditional.value];

    // If the parent has that value in it then we should show it
    return parentAnswer.findIndex((answer) => conditionalValue.includes(answer)) > -1;
};

/** loops back up the tree checking the value of all the parent's conditionals */
const checkParentConditional = (currentQuestion, questions, consultationAnswers) => {
    const conditionalId = get(currentQuestion.conditional, 'id'); // this will always exist at this point unless data is malformed from the api
    const parentQuestion = questions.find((q) => q.question_id === conditionalId);

    if (!parentQuestion) return false; // question not found, so we should fail

    const parentConditionalId = get(parentQuestion.conditional, 'id');
    const parentConditionalValue = get(parentQuestion.conditional, 'value');

    if (parentConditionalId && parentConditionalValue) {
        const parentShouldShow = checkParentConditional(parentQuestion, questions, consultationAnswers);
        if (parentShouldShow === false) return false;
    }

    return shouldThisShow(currentQuestion, consultationAnswers);
};

/**
 * filters the conditionals out based upon the answers, we use this in a lot of places.
 * @param {*} questions
 * @param {*} consultationAnswers
 */
export const filterConditionalQuestions = (questions, consultationAnswers) =>
    questions && questions.length
        ? questions.filter((q) => {
            if (!get(q.conditional, 'id') || !get(q.conditional, 'value')) return true;

            // If the answers for the conditional question id could not be found.
            if (!consultationAnswers[q.conditional.id]) return false;

            return checkParentConditional(q, questions, consultationAnswers);
        })
        : [];
