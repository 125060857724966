import React from 'react';

import Typography from '../_blocks/Typography/Typography';

import styles from './PanelWrapper.module.css';

enum ContainerSizes {
    medium = 'container-medium',
    small = 'container-small',
}

interface Props {
    title: string;
    description?: string;
    children: React.ReactNode;
    className?: string;
    containerSize?: keyof typeof ContainerSizes;
}

const PanelWrapper = ({ title, description, children, className = '', containerSize = 'medium' }: Props) => (
    <div className={`${ContainerSizes[containerSize]} ${styles.panel} ${className}`}>
        <div>
            <Typography as="h2" typeset="title" size="700" lineHeight="800" className="md:type-800 md:leading-800">
                {title}
            </Typography>
            {description ? (
                <Typography
                    as="div"
                    size="100"
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            ) : null}
        </div>
        {children}
    </div>
);

export default PanelWrapper;
