import { useState } from 'react';

import { localStorageGet } from '@/helpers/localStorage';
import useLocalStorageSync from '../../../../hooks/useLocalStorageSync';
import createAnswerEntity from '../../createAnswerEntity';
import masterAnswersLookup from '../../../../helpers/masterAnswersLookup';

/**
 * Question manager, this allows us to get and set answers
 * @param {string} sync - key to sync with the local storage if required
 */
const useQuestionManager = (sync, initialAnswers) => {
    const [answers, setAnswers] = useState(sync ? localStorageGet(sync) || {} : initialAnswers || {});
    const [initialAnswersSet, setInitialAnswersSet] = useState(initialAnswers || false); // This is used so that on some pages when the questions come back at the same time as the prefill we can alter the loading state to wait for the initial set.

    /**
     *
     * Take whole answer array or object, convert it and store it in our object.
     * @param {array | object} ans
     * @param {boolean} asArray
     */
    const setInitAnswers = (ans) => {
        setAnswers(ans || {});
        setInitialAnswersSet(true);
    };

    /**
     * Updates the consultation answers.
     * @param {number} id - Id of the question.
     * @param {object} data - Answer data to replace current in state (see defaultAnswer var).
     */
    const updateAnswer = (id, data) => {
        setAnswers((state) => ({ ...state, [id]: data }));
        masterAnswersLookup.setAnswer(id, data);
    };

    /**
     * Checks if all the provided ID answers are valid. If no ID's are provided, check all answers
     * are valid.
     * @param {array} questions - Array of question ID's to check validation against.
     */
    const checkAnswersAreValid = (questions = []) => {
        // eslint-disable-line no-unused-vars
        if (!questions.length) alert("No questions we're found, please contact us for support.");

        const newAnwswerState = {};

        let isValid = true;

        questions.forEach((q, i) => {
            const id = q.question_id;

            /** This is here to get the answer from the original state. But it serves another purpose of looping through the filtered questions - so that if a conditional has been answered and then the conditional disappears, we dont get that in our final state. */
            newAnwswerState[id] = answers[id];

            // If a question has already been answered, use the answer from the state (cloned state).
            if (newAnwswerState[id]) {
                newAnwswerState[id].isDirty = true;
                newAnwswerState[id].moreDetailDirty = true;
            } else {
                // If not, add a new answer skeleton to the state (cloned state).
                newAnwswerState[id] = createAnswerEntity({}, q);
            }

            /** For keeping an order. Later ticket */
            newAnwswerState[id].order = i;

            /** Only check validation if something if we're still valid. */
            if (isValid) {
                isValid = newAnwswerState[id].questionValid;
            }
        });

        return [isValid, newAnwswerState];
    };

    /**
     * Checks if all the provided ID answers are valid. If no ID's are provided, check all answers
     * are valid. and filter the answers by the questions provided.
     * @param {array} questions - Array of question ID's to check validation against.
     */
    const checkAnswersAreValidAndFilterAnswers = (questions = []) => {
        // eslint-disable-line no-unused-vars
        if (!questions.length) alert("No questions we're found, please contact us for support.");

        const [isValid, newAnwswerState] = checkAnswersAreValid(questions);

        // Update the state with the cloned state.
        setAnswers((state) => ({ ...state, ...newAnwswerState }));

        return !isValid ? false : newAnwswerState;
    };

    /**
     * Checks filtersAnswersByQuestions
     * @param {array} questions - Array of question ID's to check validation against.=
     */
    const filterAnswersByQuestions = (questions = []) => {
        if (!questions.length) alert("No questions we're found, please contact us for support.");

        const newAnwswerState = {};

        questions.forEach((q, i) => {
            const id = q.question_id;

            /** This is here to get the answer from the original state. But it serves another purpose of looping through the filtered questions - so that if a conditional has been answered and then the conditional disappears, we dont get that in our final state. */
            newAnwswerState[id] = answers[id];

            // If answer has not been created create one now.
            if (!newAnwswerState[id]) {
                newAnwswerState[id] = createAnswerEntity(
                    {
                        isDirty: false,
                        moreDetailDirty: false,
                    },
                    q
                );
            }

            if (newAnwswerState[id]) {
                /** For keeping an order. Later ticket */
                newAnwswerState[id].order = i;
            }
        });

        return newAnwswerState;
    };

    /**
     * Clears the answers stored in "answers" state.
     */
    const clearAnswers = () => setAnswers({});

    /**
     * If sync is set then we use that string and sync that to our local storage.
     */
    useLocalStorageSync(sync, sync && answers);

    return {
        setInitAnswers,
        initialAnswersSet,

        answers,
        updateAnswer,

        checkAnswersAreValid,
        checkAnswersAreValidAndFilterAnswers,
        filterAnswersByQuestions,
        clearAnswers,
    };
};

export default useQuestionManager;
