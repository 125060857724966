import { useState, useLayoutEffect } from 'react';

import { selectorScrollTo } from '@/helpers/scrollTo';

/**
 * Used to scroll to an error on click
 * @param {string} selector - A css selecto.
 */
const useScrollToFormErrors = (selector = '') => {
    const [runScroll, setRunScroll] = useState(false);

    useLayoutEffect(() => {
        if (runScroll) {
            setRunScroll(false);
            selectorScrollTo(selector);
        }
    }, [runScroll, selector]);

    return () => setRunScroll(true);
};

export default useScrollToFormErrors;
