import get from 'lodash.get';
import componentIndex from '../../questionComponentIndex';

/**
 * gets the component export from our question types
 * @param {*} type
 * @returns
 */
const getQuestionComponent = (type) => get(componentIndex[type], 'default');

export default getQuestionComponent;
