import get from 'lodash.get';
import componentIndex from '../../questionComponentIndex';

/**
 * Gets the question error message from the question type component file.
 * @param {string|undefined} type - The type of question
 * @returns
 */
export const getQuestionComponentErrMsg = (type) => get(componentIndex[type], 'defaultErrorMessage');

/**
 * Gets the question required error message from the question type component file.
 * @param {string} type - The type of question.
 * @returns {string|undefined}
 */
export const getQuestionRequiredErrMsg = (type) => get(componentIndex[type], 'requiredErrorMessage');
