import React, { Fragment, useEffect, useState } from 'react';

import HelpContent from './HelpContent/HelpContent';
import Icon from '../../Icon/Icon';

interface Props {
    content: string;
}

// TODO: Rename helper to question helper
// TODO: css rename form-helper to question helper. this is specific to the question forms, we may have lifted this too early.
const Helper = ({ content }: Props) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const openOverlay = () => {
        setShowOverlay(true);
    };

    const closeOverlay = () => {
        setShowOverlay(false);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('close_all_helpers', closeOverlay, false);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('close_all_helpers', closeOverlay);
            }
        };
    }, [showOverlay]);

    return (
        <Fragment>
            <button
                type="button"
                onClick={showOverlay ? closeOverlay : openOverlay}
                title="View more information about this question"
                className="flex items-center gap-050 mb-100 type-090 type-link-reverse"
            >
                <Icon icon="help-light" alt="" aria-hidden="true" size="medium" />
                {showOverlay ? 'Hide help?' : 'Need help?'}
            </button>
            {showOverlay && <HelpContent content={content} />}
        </Fragment>
    );
};

export default Helper;
