import React from 'react';

import Wysiwyg from '../../../Wysiwyg/Wysiwyg';

import styles from './HelpContent.module.css';

interface Props {
    content: string;
}

const HelpContent = ({ content }: Props) => <Wysiwyg html={content} className={`${styles.helpContent} !type-080`} />;

export default HelpContent;
