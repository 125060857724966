import { useState } from 'react';

const useStepper = (defaultStep = 0) => {
    const [currentStep, setCurrentStep] = useState(defaultStep);

    return {
        currentStep,
        setCurrentStep,
        nextStep: () => setCurrentStep((s) => s + 1),
        previousStep: () => setCurrentStep((s) => s - 1),
    };
};

export default useStepper;
