/* eslint-disable no-param-reassign */
import shouldShowMoreDetail from './shouldShowMoreDetail';
import { getQuestionComponentErrMsg, getQuestionRequiredErrMsg } from './getQuestionErrorMessages';

export const DEFAULT_QUESTION_ERROR_MSG =
    'Sorry, your answer indicates that this medicine may not be suitable for you to purchase online. Please contact us for further advice, see our referrals page or click the "help" icon for more information, if applicable.';
export const REQUIRED_QUESTION_ERROR_MSG = 'This is field required';

/**
 * get the error message to display to the user.
 * We show the required error message if there is no value set and its a required field regardless
 *
 * We get the default error message & We get the component exported default error message. showing one of those if no other error messages are set.
 * We check if its a moreDetail error to display
 * And we get the api error message sent
 * We use all the above to determine what error message to show.
 * @param {*} storedAnswer
 * @param {*} moreDetail
 * @param {*} required
 * @param {*} type
 * @param {*} errorMessage - from api
 * @returns
 */
const getErrorMessage = (type, storedAnswer, moreDetail, required, errorMessage) => {
    const defaultErrorMessage = getQuestionComponentErrMsg(type);
    const requiredErrorMessage = getQuestionRequiredErrMsg(type);

    /** if the question has passed back some error that the question itself is displaying then don't bother displaying any other errors. */
    if (storedAnswer.internalQuestionError) return '';

    // Doing it this way accounts for empty string for error messages coming back.
    if (!errorMessage) errorMessage = defaultErrorMessage || DEFAULT_QUESTION_ERROR_MSG;

    if (required && (storedAnswer.answer === null || typeof storedAnswer.answer === 'undefined')) {
        return requiredErrorMessage || REQUIRED_QUESTION_ERROR_MSG;
    }

    /** Check to see if more detail is set or required. */
    return shouldShowMoreDetail(storedAnswer.answer, moreDetail) ? 'Please provide more information.' : errorMessage;
};

export default getErrorMessage;
