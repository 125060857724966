import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAssessmentContext } from '../../../../data/context/assessmentContext/assessmentContext';
import useScrollToFormErrors from '../../../_ui/_blocks/Form/hooks/useScrollToFormErrors';
import { API_ERROR_MESSAGE } from '../../../../constants';
import { filterConditionalQuestions } from '../../../Questions/helpers/filterConditional/filterConditional';

import Attention from '../../../_ui/_blocks/Attention/Attention';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import PanelWrapper from '../../../_ui/Panels/PanelWrapper';
import Question from '../../../Questions/Question';
import ButtonBusy from '../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';

const questions = [
    {
        question_id: 'other_treatments',
        question_type: 'yes-no',
        question_text: 'Have you tried any treatments for your symptoms? Were they effective?',
        question_description: '',
        error_message: 'Please fill in this question',
        more_detail_trigger: 1,
        required: 1,
    },
    {
        question_id: 'other_medication',
        question_type: 'yes-no',
        question_text: 'Do you take any other medications?',
        question_description: '',
        error_message: 'Please fill in this question',
        more_detail_trigger: 1,
        required: 1,
    },
    {
        question_id: 'other_information',
        question_type: 'free-text',
        question_text: 'Please add any other information you think might be useful to your assessment.',
        question_description: '',
        error_message: 'Please fill in this question',
        required: 0,
    },
];

const AssessmentMedialQuestions = ({ nextPanel, previousPanel }) => {
    const {
        medicalAnswers,
        updateMedicalAnswer,
        checkMedicalAnswersAreValidAndFilterAnswers,

        closeAssessment,

        isSubmitting,
        submitAssessment,
        submitErrors,
        clearAssessmentAnswers,
    } = useAssessmentContext();

    const filteredQuestions = filterConditionalQuestions(questions, medicalAnswers);

    const scrollToErrors = useCallback(useScrollToFormErrors('.question-card--has-error'), []);

    const handleSubmit = async () => {
        if (!checkMedicalAnswersAreValidAndFilterAnswers(filteredQuestions.map((q) => q))) {
            scrollToErrors();
            return;
        }

        if (!(await submitAssessment())) return;

        clearAssessmentAnswers();
        nextPanel();
    };

    const submissionError = useMemo(() => {
        if (!submitErrors) return null;

        return (
            <Attention type="error" heading="Submission error">
                <p className="text-content-quiet">{API_ERROR_MESSAGE}</p>
            </Attention>
        );
    }, [submitErrors]);

    return (
        <PanelWrapper
            title="Current medications"
            description="Tell us about any treatments or medications you currently take."
            closePanel={closeAssessment}
            back={previousPanel}
        >
            <div className="space-y-100">
                {medicalAnswers
                    ? filteredQuestions.map((q) => (
                        <Question
                            {...q}
                            key={q.question_id}
                            id={q.question_id}
                            type={q.question_type}
                            text={q.question_text}
                            description={q.question_description}
                            errorMessage={q.error_message}
                            helper={q.helper_text}
                            required={q.required}
                            moreDetail={q.more_detail_trigger}
                            options={q.options}
                            conditional={q.conditional}
                            storedAnswer={medicalAnswers[q.question_id]}
                            updateAnswer={updateMedicalAnswer}
                        />
                    ))
                    : null}
                {submissionError}
                <div className="flex justify-end gap-100 py-100">
                    <Button variant="secondary" type="button" onClick={closeAssessment} title="Cancel your assessment">
                        Cancel
                    </Button>
                    <ButtonBusy
                        variant="primary"
                        loading={isSubmitting}
                        title="Submit your assessment"
                        onClick={handleSubmit}
                        disabled={!!submissionError}
                    >
                        Submit assessment
                    </ButtonBusy>
                </div>
            </div>
        </PanelWrapper>
    );
};

AssessmentMedialQuestions.defaultProps = {
    previousPanel: null,
};

AssessmentMedialQuestions.propTypes = {
    nextPanel: PropTypes.func.isRequired,
    previousPanel: PropTypes.func,
};

export default AssessmentMedialQuestions;
