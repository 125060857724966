import { useState, useEffect } from 'react';

import get from 'lodash/get';

import isAnswerEmpty from '../helpers/isAnswerEmpty';
import masterAnswersLookup from '../../../helpers/masterAnswersLookup';

// Excludes question types from using the prefill component in this component.
const EXCLUDE_QUESTION_TYPES_FROM_PREFILL = [
    's-gp', // Prefill is done manually inside this question type component.
    'gender',
];

/**
 * // Hook to manage prefill state of a question.
 * @param {string} type - The type of question
 * @param {string|number} id - Id of the question.
 * @param {string|object|number} answer - The answer for the question.
 * @param {func} onPrefill - Function to run if question should be prefilled.
 * @returns {object}
 */
const usePrefillAnswer = (type, id, answer, onPrefill, question) => {
    const [isPrefilled, setIsPrefilled] = useState(
        EXCLUDE_QUESTION_TYPES_FROM_PREFILL.includes(type) === false && isAnswerEmpty(answer) === false
    );

    /** Master answer lookup work to answer question. */
    useEffect(() => {
        if (EXCLUDE_QUESTION_TYPES_FROM_PREFILL.includes(type)) {
            return;
        }

        /** If a question is passed in, also if it's been set in the cp to always be asked then dont get the prefill */
        if (get(question, 'never_autofill') === 1) {
            return;
        }

        const masterAnswers = masterAnswersLookup.getAllAnswers();
        const masterSelectedAnswer = masterAnswers[id];

        if (masterSelectedAnswer && masterSelectedAnswer.isDirty === true) {
            onPrefill(masterSelectedAnswer);
            setIsPrefilled(true);
        }
    }, []);

    return {
        isPrefilled,
        setIsPrefilled,
    };
};

export default usePrefillAnswer;
