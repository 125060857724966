import React, { useMemo } from 'react';
import dayjs from '@/lib/dayjs/dayjs';

import Helper from '../_blocks/Form/Helper/Helper';
import Error from '../_blocks/Form/Validation/Error/Error';
import Card from '../_blocks/Card/Card';
import Typography from '../_blocks/Typography/Typography';
import Icon from '../_blocks/Icon/Icon';

import styles from './QuestionCard.module.css';

export interface Props {
    id?: number | string;
    required: number;
    question: string;
    description?: string;
    answeredOn?: number;
    errorMessage?: string;
    helper?: string;
    showError: boolean;
    isDirty?: boolean;
    type: string;
    children?: React.ReactNode;
}

const QuestionCard = ({
    question,
    showError,
    required,
    type,
    children,
    id = undefined,
    description = undefined,
    answeredOn = undefined,
    errorMessage = undefined,
    helper = undefined,
    isDirty = undefined,
}: Props) => {
    const providedOn = useMemo(() => {
        if (!answeredOn) return null;

        const date = dayjs.utc(answeredOn).local();

        // Return no date if the answered on date is the same as todays date.
        return date.isSame(dayjs(), 'day') ? null : date;
    }, [answeredOn]);

    const hasError = isDirty && !!showError;

    let className = styles.questionCard;
    className += isDirty ? ` ${styles.dirty}` : '';
    className += required && (!isDirty || !!showError) ? ` ${styles.currentStep}` : '';

    return (
        <Card
            variant="bordered"
            borderColor={hasError ? 'error' : 'default'}
            className={className}
            data-id={id}
            disableBoxShadow
        >
            <header className="space-y-050 flex-shrink-0 md:w-[19rem] md:basis-2/5">
                {!required
                    ? <Typography as="div" size="070" color="quiet">This question is optional</Typography>
                    : null}
                <div className="flex justify-between items-start gap-100">
                    {question ? <div className={styles.question} dangerouslySetInnerHTML={{ __html: question }} /> : null}
                    <Icon
                        icon={showError ? 'error-status' : 'success-status'}
                        alt={showError ? 'Question answer invalid' : 'Question completed'}
                        className={styles.icon}
                        size="medium"
                    />
                </div>
                {required && type === 'multiple-selection'
                    ? <Typography as="div" size="090">Select one or more answers</Typography>
                    : null}
                {required && type === 'radio'
                    ? <Typography as="div" size="090">Select one answer</Typography>
                    : null}
                {description
                    ? <div className={styles.questionDescription} dangerouslySetInnerHTML={{ __html: description }} />
                    : null}
                {!answeredOn && helper ? <Helper content={helper} /> : null}
                {hasError && errorMessage !== ''
                    ? <Error className={`${styles.formError} hidden md:flex`}>{errorMessage}</Error>
                    : null}
            </header>
            <div className="space-y-050 md:space-y-100 flex-grow">
                {providedOn ? (
                    <Typography as="div" size="070" className={styles.providedOn}>
                        You previously answered this on{' '}
                        <time dateTime={providedOn.format('YYYY-MM-DD')}>{providedOn.format('DD/MM/YYYY')}</time>
                    </Typography>
                ) : null}
                {children}
                {hasError && errorMessage !== ''
                    ? <Error className={`${styles.formError} md:hidden`}>{errorMessage}</Error>
                    : null}
            </div>
        </Card>
    );
};

export default QuestionCard;
