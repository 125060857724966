import React from 'react';

import Card from '../../_ui/_blocks/Card/Card';

const AssessmentSkeleton = () => (
    <div className="container-xsmall container-gutters-medium mx-auto space-y-100">
        <Card as="div" variant="bordered" className="space-y-100">
            <div className="space-y-050">
                <div className="skeleton-animation h-[1.25rem] w-[4.25rem]" />
                <div className="skeleton-animation h-[3rem]" />
            </div>
            <div className="space-y-050">
                <div className="skeleton-animation h-[1.25rem] w-[4.25rem]" />
                <div className="skeleton-animation h-[3rem]" />
            </div>
            <div className="space-y-050">
                <div className="skeleton-animation h-[1.25rem] w-[4.25rem]" />
                <div className="skeleton-animation h-[3rem]" />
            </div>
            <div className="space-y-050">
                <div className="skeleton-animation h-[1.25rem] w-[4.25rem]" />
                <div className="skeleton-animation h-[3rem]" />
            </div>
        </Card>
        <div className="flex gap-100 justify-end">
            <div className="skeleton-animation w-[5.75rem] h-[3.5rem]" />
            <div className="skeleton-animation w-[13.5rem] h-[3.5rem]" />
        </div>
    </div>
);

export default AssessmentSkeleton;
