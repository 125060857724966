'use client';

import React, { useState } from 'react';

import useStepper from '../../hooks/useStepper';

import AssessmentContactDetails from './AssessmentPanels/AssessmentContactDetails/AssessmentContactDetails';
import AssessmentSymptoms from './AssessmentPanels/AssessmentSymptoms/AssessmentSymptoms';
import AssessmentMedialQuestions from './AssessmentPanels/AssessmentMedialQuestions/AssessmentMedialQuestions';
import AssessmentCompleted from './AssessmentPanels/AssessmentCompleted/AssessmentCompleted';
import PanelToPanelAnimation from '../_ui/Panels/PanelToPanelAnimation';

const Assessment = () => {
    const { currentStep, nextStep, previousStep } = useStepper();

    const [panels] = useState([
        {
            component: AssessmentContactDetails,
            key: 'contact-details',
            animate: true,
        },
        {
            component: AssessmentSymptoms,
            key: 'symptoms',
            animate: false,
        },
        {
            component: AssessmentMedialQuestions,
            key: 'medical-questions',
            animate: true,
        },
        {
            component: AssessmentCompleted,
            key: 'completed',
            animate: true,
        },
    ]);

    const CurrentComponent = panels[currentStep].component;

    return (
        <PanelToPanelAnimation keyName={panels[currentStep].key} shouldAnimate={panels[currentStep].animate}>
            <CurrentComponent nextPanel={nextStep} previousPanel={previousStep} />
        </PanelToPanelAnimation>
    );
};

export default Assessment;
